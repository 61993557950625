/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-07",
    versionChannel: "nightly",
    buildDate: "2023-05-07T00:23:17.786Z",
    commitHash: "724df481863c3df1b236a783a83c733b2a4a2341",
};
